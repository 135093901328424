import {defineStore} from "pinia";
import CheckpointSummary from "~/dto/checkpoint/Summary";
import TrackingSession from "~/dto/queue/TrackingSession";
import User from "~/dto/User";
import type SummaryResponse from "~/api/response/SummaryResponse";
import type TrackingSessionData from "~/api/response/data/queue/TrackingSessionData";
import type CheckpointSummaryData from "~/api/response/data/checkpoint/SummaryData";
import type QueuesCountsData from "~/api/response/data/queue/QueuesCountsData";
import type PublicSummaryData from "~/api/response/data/summary/PublicSummaryData";
import type SummaryData from "~/api/response/data/summary/SummaryData";
import type UserData from "~/api/response/data/UserData";
import _ from "lodash";

export const useSummaryStore = defineStore('summary', () => {
    const checkpointsSummaryData = ref<Record<string, CheckpointSummaryData>>({});
    const checkpointsSummary = computed<CheckpointSummary[]>(() => {
        return _.map(checkpointsSummaryData.value, (data: CheckpointSummaryData) => new CheckpointSummary(data));
    });
    const openCheckpointsSummary = computed<CheckpointSummary[]>(() => {
        return _.filter(checkpointsSummary.value, (summary: CheckpointSummary) => summary.isOpen);
    });
    const isSummaryLoaded = computed<boolean>(() => {
        return checkpointsSummary.value.length > 0;
    });

    const userData = ref<UserData | null>(null);
    const user = computed<User | null>(() => {
        return userData.value ? new User(userData.value) : null;
    });

    const trackingSessionsData = ref<Record<string, TrackingSessionData>>({});
    const trackingSessions = computed<TrackingSession[]>(() => {
        return _.map(trackingSessionsData.value, (data: TrackingSessionData) => new TrackingSession(data));
    });

    const summaryPending = ref<boolean>(false);

    function setPublicSummaryData ({ checkpoints }: PublicSummaryData) {
        checkpointsSummaryData.value = _.keyBy(checkpoints || [], 'code');
    }
    function setPrivateSummaryData ({ user, tracking_sessions }: SummaryData) {
        userData.value = user;
        trackingSessionsData.value = _.keyBy(tracking_sessions || [], 'id');
    }
    function setSummaryData (data: SummaryData) {
        setPublicSummaryData(data);
        setPrivateSummaryData(data);
    }

    async function fetch () {
        if (_.size(checkpointsSummaryData.value)) {
            return checkpointsSummaryData.value;
        }

        summaryPending.value = true;
        try {
            const { data } = await apiFetch<SummaryResponse>('/summary');
            setSummaryData(data);
        } catch (error) {
            captureException(error);
        }
        summaryPending.value = false;

        return checkpointsSummaryData.value;
    }

    function addTrackingSession (session: TrackingSession) {
        trackingSessionsData.value[session.id] = session.data;
    }

    function removeTrackingSession (sessionId: string) {
        delete trackingSessionsData.value[sessionId];
    }

    function hasTrackingSession (sessionId: string) {
        return _.has(trackingSessionsData.value, sessionId);
    }

    function updateTrackingSessionData (data: TrackingSessionData) {
        trackingSessionsData.value[data.id] = data;
    }

    function updateCheckpointQueuesCountsData (code: string, data: QueuesCountsData) {
        if (checkpointsSummaryData.value[code]) {
            checkpointsSummaryData.value[code].cars_queues_counts = data;
        }
    }

    function getCheckpointData (code: string) {
        return _.find(checkpointsSummaryData.value, {code: code}) || null;
    }

    if (process.client) {
        const { echoChannel } = useEcho();
        echoChannel("summary")
            .listen('.refresh', function ({summary}: {summary: PublicSummaryData}) {
                setPublicSummaryData(summary);
            });
    }

    return {
        fetch,
        addTrackingSession,
        removeTrackingSession,
        hasTrackingSession,
        updateTrackingSessionData,
        updateCheckpointQueuesCountsData,
        getCheckpointData,
        isSummaryLoaded,
        checkpointsSummary,
        checkpointsSummaryData,
        summaryPending,
        openCheckpointsSummary,
        user,
        trackingSessions
    };
});
