import type CheckpointSummaryData from "~/api/response/data/checkpoint/SummaryData";
import CheckpointTotal from "~/dto/checkpoint/Total";
import QueuesCounts from "~/dto/queue/QueuesCounts";
import {CheckpointStatus} from "~/dto/Checkpoint";

export default class CheckpointSummary {
    code: string;
    name: string;
    status: CheckpointStatus;
    countryCode: string;
    total: CheckpointTotal | null;
    hasQueues: boolean;
    carsQueuesCounts: QueuesCounts | null;
    carsLiveQueueDuration: number | null;

    constructor(data: CheckpointSummaryData) {
        this.code = data.code;
        this.name = data.name;
        this.status = data.status as CheckpointStatus;
        this.countryCode = data.country_code;
        this.total = data.total ? new CheckpointTotal(data.total) : null;
        this.hasQueues = data.has_queues;
        this.carsQueuesCounts = data.cars_queues_counts ? new QueuesCounts(data.cars_queues_counts) : null;
        this.carsLiveQueueDuration = data.cars_live_queue_duration || null;
    }

    get isOpen() {
        return CheckpointStatus.Open === this.status;
    }

    get isClosed() {
        return CheckpointStatus.Closed === this.status;
    }
}