import type QueueItemData from "~/api/response/data/queue/QueueItemData";
import Checkpoint from "~/dto/Checkpoint";
import { DateTime } from "luxon";

export const enum Queue {
    Live = 'live',
    Priority = 'priority',
}

export const enum Status {
    Arrived = 'arrived',
    Called = 'called',
    Canceled = 'canceled',
}

export default class QueueItem {
    registrationNumber: string;
    callingOrder: number | null;
    queue: string;
    status: string;
    registeredAt: DateTime;
    calledAt: DateTime | null;
    checkpoint: Checkpoint | null;

    constructor(data: QueueItemData) {
        this.registrationNumber = data.registration_number;
        this.callingOrder = data.calling_order;
        this.queue = data.queue;
        this.status = data.status as Status;
        this.registeredAt = DateTime.fromISO(data.registered_at);
        this.calledAt = data.called_at ? DateTime.fromISO(data.called_at) : null;
        this.checkpoint = data.checkpoint ? new Checkpoint(data.checkpoint) : null;
    }

    get isInLiveQueue() {
        return Queue.Live === this.queue;
    }

    get isInPriorityQueue() {
        return Queue.Priority === this.queue;
    }

    get isArrived() {
        return Status.Arrived === this.status;
    }

    get isCalled() {
        return Status.Called === this.status;
    }

    get isCanceled() {
        return Status.Canceled === this.status;
    }
}