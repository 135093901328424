import type QueuesCountsData from "~/api/response/data/queue/QueuesCountsData";

export default class QueuesCounts {
    live: number;
    priority: number;

    constructor(data: QueuesCountsData) {
        this.live = data.live;
        this.priority = data.priority;
    }
    get total() {
        return this.live + this.priority;
    }
}