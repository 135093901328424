import type TrackingSessionData from "~/api/response/data/queue/TrackingSessionData";
import QueueItem from "~/dto/queue/QueueItem";

export default class TrackingSession {
    data: TrackingSessionData

    id: string;
    item: QueueItem;
    isGuestSession: boolean;

    constructor(data: TrackingSessionData) {
        this.data = data;

        this.id = data.id;
        this.item = new QueueItem(data.item);
        this.isGuestSession = data.guest;
    }
}